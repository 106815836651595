import _ from 'lodash';
import { from, of } from 'rxjs';
import { ThalosApiService } from 'src/app/core/services/thalos-api.service';
import { ListResponse } from 'src/lib/ListResponse';
import { endpoints } from 'src/lib/apiEndpoints';
import { JournalEntry, SourceEntityType, YN } from 'src/lib/newBackendTypes';
import { DynamicFormConstant, DynamicFormType, checkPrefillCallback, createFormCallback, prefillCallback, submitFormCallback } from './types';
import { JournalReconciliation } from 'src/lib/newBackendTypes/journalReconciliation';

/**
 *  TODO - issue-3339: remove this form once Bank Reconciliation is done
 */

export const bankReconcileJournalEntriesPrefill: prefillCallback<BankReconcileJournalEntriesPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api') as ThalosApiService;
      const journalEntryIds = Array.isArray(id) ? id : [id];
      const listJournals = await api.run<ListResponse<JournalReconciliation>>(endpoints.listJournalReconciliation, {
        filters: { journalEntryId: journalEntryIds },
      });
      const journalEntries = listJournals.list;

      return { journalEntries };
    })()
  );
};

const bankReconcileJournalEntriesCheck: checkPrefillCallback<BankReconcileJournalEntriesPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);

  const entryIdsToMatch = new Set(prefill.journalEntries);
  if (prefill.journalEntries.length !== entryIdsToMatch.size) return of('Unable to Bank reconcile journals: some entries are not available.');

  for (const journalEntry of prefill.journalEntries) if (journalEntry.matched === YN.Y) return of('Unable to Bank reconcile journals: all entries should be unmatched');

  return of(true);
};

const bankReconcileJournalEntriesForm: createFormCallback<BankReconcileJournalEntriesPrefill, BankReconcileJournalsRequest> = (delegate, id, prefill) => {
  return [
    {
      type: 'Label',
      text: `Are you sure you want to Bank reconcile Journal Entries: ${
        prefill.journalEntries ? prefill.journalEntries.map((item) => `${item.journalNumber} - ${item.entryPosition}`).join(', ') + '?' : 'Unknown'
      }`,
      style: { 'margin-bottom': '25px', display: 'flex', 'justify-content': 'center' },
    },
  ];
};

const bankReconcileJournalEntriesCallback: submitFormCallback<BankReconcileJournalEntriesPrefill, BankReconcileJournalsRequest> = (delegate, id, result, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<JournalEntry[] | null>((resolve, reject) => {
      (async () => {
        const journalEntries: { journalEntryId: number; matched: YN }[] = [];
        const matchableItems = Array.isArray(prefill.journalEntries) ? prefill.journalEntries : [prefill.journalEntries];
        for (const entry of matchableItems) {
          journalEntries.push({ journalEntryId: entry.journalEntryId, matched: YN.Y });
        }

        const request: BankReconcileJournalsRequest = { journalEntries };

        try {
          const JournalReconciliation = await api.run<JournalEntry[]>(endpoints.bankReconcileUnreconcileEntries, request, null);
          return resolve(JournalReconciliation);
        } catch (err) {
          return reject(err);
        }
      })();
    })
      .then((res) => {
        if (!!res && Array.isArray(res) && res.length > 0) {
          const response = res.filter((item) => !_.isNil(item));
          if (response.length > 0) return prompt.htmlDialog('Success', `<div style="white-space: pre">Journals successfully Bank reconciled: ${response.length} line(s).</div>`);
        }
      })
      .catch(() => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Unknown result. Please check if the journals were Bank reconciled and try again if necessary.</div>`);
      })
  );
};

export const bankReconcileJournalEntriesPreset: DynamicFormConstant<BankReconcileJournalEntriesPrefill, BankReconcileJournalsRequest> = {
  allowMultipleRows: true,
  checkPrefill: bankReconcileJournalEntriesCheck,
  createForm: bankReconcileJournalEntriesForm,
  entityType: SourceEntityType.JOURNAL_ENTRY_ID,
  getPrefill: bankReconcileJournalEntriesPrefill,
  label: 'Bank Reconcile Journal Entries',
  submitForm: bankReconcileJournalEntriesCallback,
  title: 'Bank Reconcile Journal Entries',
  value: DynamicFormType.BANK_JOURNAL_RECONCILIATION,
  endpoints: [endpoints.bankReconcileUnreconcileEntries, endpoints.listJournalReconciliation],
  width: 600,
};

export type BankReconcileJournalsRequest = {
  journalEntries: {
    journalEntryId: number;
    matched: YN;
  }[];
};
export type BankReconcileJournalEntriesPrefill = { journalEntries: JournalReconciliation[] };
