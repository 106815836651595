import { Validators } from '@angular/forms';
import { from, of } from 'rxjs';
import { endpoints } from 'src/lib/apiEndpoints';
import { bradyDateValidator } from 'src/lib/genericValidators';
import { getTodayUTC } from 'src/lib/helperFunctions';
import { CreateJournalRequest, EntryOperation, JournalHeader, SourceEntityType, UpdateJournalRequest } from 'src/lib/newBackendTypes';
import { ReconcileJournalsRequest } from './reconcileJournalEntries';
import { checkPrefillCallback, createFormCallback, DynamicFormConstant, DynamicFormType, prefillCallback, submitFormCallback } from './types';

const reverseJournalPrefill: prefillCallback<ReverseJournalPrefill> = (delegate, id) => {
  return from(
    (async () => {
      const api = delegate.getService('api');
      const journalsIds = Array.isArray(id) ? id : [id];
      const journalResponses: JournalHeader[] = [];
      for (const id of journalsIds) {
        const journalInfo = await api.run<JournalHeader>(endpoints.getJournal, { filters: { id: id } }, null);
        journalResponses.push(journalInfo);
      }

      return { journalResponses };
    })()
  );
};

const reverseJournalCheck: checkPrefillCallback<ReverseJournalPrefill> = (delegate, id, prefill) => {
  if (!prefill) return of(false);
  if (prefill.journalResponses.length === 0) return of('No journals to be reversed.');
  for (let item of prefill.journalResponses) {
    if (item.entryOperation !== EntryOperation.TO_REVERSE) return of('Unable to Reverse Journal(s): some journal operation is not to reverse.');
  }
  return of(true);
};

const reverseJournalForm: createFormCallback<ReverseJournalPrefill, ReverseJournalForm> = (delegate, id, prefill) => {
  const journals = Array.isArray(prefill.journalResponses) ? prefill.journalResponses : [prefill.journalResponses];

  return [
    {
      type: 'Label',
      text: `Reverse Journal(s): ${prefill ? 'ID(s): ' + journals.map((item) => item.id).join(', ') : 'Unknown'}`,
    },
    [
      {
        type: 'Date',
        field: 'valueDate',
        label: 'Value Date',
        validator: [Validators.required, bradyDateValidator()],
        startingValue: new Date(),
      },
    ],
  ];
};

const reverseJournalCallback: submitFormCallback<ReverseJournalPrefill, ReverseJournalForm> = (delegate, id, form, prefill) => {
  const api = delegate.getService('api');
  const prompt = delegate.getService('prompt');
  return from(
    new Promise<JournalHeader[]>((resolve, reject) => {
      (async () => {
        const createJournalResponses: JournalHeader[] = [];

        for (const res of prefill.journalResponses) {
          const createJournalRequest: CreateJournalRequest = {
            ...res,
            entryOperation: EntryOperation.REVERSE,
            externalRef: `*${res.externalRef}`,
            valueDate: form.valueDate,
            entryDate: getTodayUTC(),
            entries: res.journalEntries.map((entry) => {
              return {
                ...entry,
                amount: -entry.amount,
                baseAmount: -entry.baseAmount,
                productQuantity: entry.productQuantity ? entry.productQuantity * -1 : 0,
              };
            }),
          };
          const reversal = await api.run<JournalHeader>(endpoints.createJournal, createJournalRequest, null);
          if (!reversal) return reject('Unknown result. Please check if the journal(s) were reversed and try again if necessary.');
          createJournalResponses.push(reversal);

          const updateJournalRequest: UpdateJournalRequest = {
            id: res.id,
            entries: res.journalEntries,
            entryOperation: EntryOperation.REVERSED_ITEM,
          };
          await api.run<JournalHeader>(endpoints.updateJournal, updateJournalRequest);

          if (delegate.commonData.authorized[endpoints.reconcileJournalEntries]) {
            const journalEntries = new Map<number, number[]>();
            for (const journalEntry of [...res.journalEntries, ...reversal.journalEntries]) {
              const accountEntries = journalEntries.get(journalEntry.accountId) || [];
              accountEntries.push(journalEntry.journalEntryId);
              journalEntries.set(journalEntry.accountId, accountEntries);
            }
            for (const journalEntryIds of Array.from(journalEntries.values())) {
              const reconcileRequest: ReconcileJournalsRequest = {
                journalEntryIds,
                title: `Reconcile Reversal`,
              };
              await api.run<any>(endpoints.reconcileJournalEntries, reconcileRequest);
            }
          }
        }

        if (createJournalResponses) return resolve(createJournalResponses);
        return reject('Unknown result. Please check if the journal(s) were reversed and try again if necessary.');
      })();
    })
      .then((res) => {
        if (res) {
          const response = res.filter((item) => item !== null);
          if (response) {
            return prompt.htmlDialog('Success', `<div style="white-space: pre">Journal(s) successfully reversed: \n${response.map((journal) => `- ${journal.journalIndex}`).join(`\n`)}</div>`);
          }
        }
      })
      .catch((error) => {
        return prompt.htmlDialog('Error', `<div style="white-space: pre">${error}</div>`);
      })
  );
};

export const reverseJournalPreset: DynamicFormConstant<ReverseJournalPrefill, ReverseJournalForm> = {
  allowMultipleRows: true,
  checkPrefill: reverseJournalCheck,
  createForm: reverseJournalForm,
  entityType: SourceEntityType.JOURNAL_ID,
  getPrefill: reverseJournalPrefill,
  label: 'Reverse Journal',
  submitForm: reverseJournalCallback,
  title: 'Reverse Journal',
  value: DynamicFormType.REVERSE_JOURNAL,
  endpoints: [endpoints.getJournal, endpoints.createJournal, endpoints.updateJournal, endpoints.reconcileJournalEntries],
  width: 500,
};

export type ReverseJournalPrefill = {
  journalResponses: JournalHeader[];
};
export type ReverseJournalForm = {
  valueDate: Date;
  journalId: number;
};
