import { ContractLine, PreviousDocument } from '.';
import { CheckListEntry } from './checklist';
import { City } from './city';
import { Contact } from './contact';
import { ChunkType, Container } from './container';
import { PhysicalContract } from './contract';
import { Delivery } from './delivery';
import { BaseDiscrepancy, DiscrepancyWeightTicket } from './discrepancy';
import { YN } from './enums';
import { Booking } from './freightBooking';
import { Item } from './item';
import { MetalControlStatus } from './purchaseMatchData';
import { ServiceOrder } from './serviceOrder';
import { ShipmentPacking } from './shipment';
import { ShipmentPurchaseSaleData } from './shipmentPurchaseSaleData';
import './unit';
import { Unit, UnitInfo } from './unit';
import { WarehouseMovement } from './warehouseMovement';
import { WeightTicket } from './weightTicket';

export type PropertyDocument = {
  id: number;
  buyDeliveryId: number | null;
  selDeliveryId: number | null;
  companyId: number | null;
  status: ChunkType | null;
  chunkNumber: string | null;
  chunkDate: Date | null;
  applicationDate: Date | null;
  loadingPortId: number;
  destinationPortId: number;
  numberOfBagsDeclared: number;
  bagsLeft: number | null;
  bagsUnknown: number | null;
  grossWeight: number;
  grossWeightLeft: number | null;
  grossWeightUnknown: number | null;
  netWeight: number;
  netWeightLeft: number | null;
  netWeightUnknown: number | null;
  commercialWeight: number | null;
  commercialWeightUnitKey: number | null;
  unitId: number;
  textZone: string | null;
  itemId: number | null;
  documentStatus: number | null;
  saleSurveyerKey: number | null;
  orebiSurveyerKey: number | null;
  buyInvoicingStatus: number | null;
  selInvoicingStatus: number | null;
  characteristicKey1: number | null;
  characteristicKey2: number | null;
  loadingQuantity: number;
  methodKey: number | null;
  bankCode: number | null;
  finalBankCode: number | null;
  matchingDate: Date | null;
  buyCurrencyId: number | null;
  saleCurrencyId: number | null;
  buyPrice: number;
  sellPrice: number | null;
  buyPriceUnitKey: number | null;
  sellPriceUnitKey: number | null;
  buyPriceUpdated: YN | null;
  sellPriceUpdated: YN | null;
  buyInvoiceAmount: number;
  sellInvoiceAmount: number | null;
  offerKey: number | null;
  matchingStatus: number | null;
  stockValue: number | null;
  marks: string | null;
  documentName: string;
  norDate: number | null;
  numberOfPieces: number | null;
  statusModifiedBy: string | null;
  statusModifyDate: Date | null;
  insurancePrice: number | null;
  submitStatus: number | null;
  dvtActive: YN | null;
  buyFinalizedWeight: YN | null;
  buyFinalizedPrice: YN | null;
  selFinalizedWeight: YN | null;
  selFinalizedPrice: YN | null;
  lcPresentKey: number | null;
  productId: number;
  productCounterparty: number;
  createdBy: number | null;
  creationDate: Date | null;
  updatedBy: number | null;
  updateDate: Date | null;
  versionNb: number;
  mtmDifferential: number | null;
  warehouseRefNumber: number | null;
  readonly containerMarks: string;
  containerId: number | null;
  mcNotes: string;
  mcStatus: MetalControlStatus;
  /**
   * @deprecated TO-DO issue-2924: delete column
   */
  purchaseDeclarationId: number | null;
  /**
   * @deprecated TO-DO issue-2924: delete column
   */
  saleDeclarationId: number | null;
  purchaseElementId: number | null;
  saleElementId: number | null;
  valueDate: Date;
  refReceived: string;
  lotNumber: string;
  purchaseDelivery?: Delivery;
  saleDelivery?: Delivery;
  bookings?: Booking[];
  quantityUnit?: Unit;
  quantityUnitFactor?: UnitInfo;
  item?: Item;
  weightTickets?: WeightTicket[];
  discrepancyWeightTickets?: DiscrepancyWeightTicket[];
  discrepancies?: BaseDiscrepancy[];
  serviceOrders?: ServiceOrder[];
  container?: Container | null;
  packingTypes?: ShipmentPacking[];
  destination?: City;
  origin?: City;
  // transports?: Transport[]
  checkListEntries?: CheckListEntry[];
  childShipmentRelations?: PreviousDocument[];
  parentShipmentRelations?: PreviousDocument[];
  contractData?: ShipmentPurchaseSaleData; //Never sent from backend, only set manually
  warehouseMovements?: WarehouseMovement[];
  purchaseElement?: ContractLine;
  saleElement?: ContractLine;
};
export type PartialContact = Pick<Contact, 'id' | 'displayName' | 'decimalPrecision'>;
export type PartialContract = Pick<PhysicalContract, 'id' | 'number'> & {
  counterparty?: PartialContact;
};
