import { Directive, ElementRef, NgZone, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { NumberFormatOptions } from '@progress/kendo-intl';
import { combineLatest, lastValueFrom, Observable, of } from 'rxjs';
import { debounceTime, filter, map, pairwise, startWith, switchMap, tap } from 'rxjs/operators';
import { BusinessLogicService } from 'src/app/core/services/business-logic-service';
import { CommonDataService } from 'src/app/core/services/common-data.service';
import { DelegateService } from 'src/app/core/services/delegate-service.service';
import { PromptService } from 'src/app/core/services/prompt.service';
import { SimpleDropdownComponent } from 'src/app/shared/form-elements/simple-dropdown/simple-dropdown.component';
import { RateFinderComponent, RateRequestedResponse } from 'src/app/shared/rate-finder/rate-finder.component';
import { DropdownConfig, EntityContainer, EntityFormOptions } from 'src/lib';
import { endpoints } from 'src/lib/apiEndpoints';
import { budgetElementDropdown, contactDropdown, counterpartyDropdown, dollarFormat, traderDropdown } from 'src/lib/commonTypes';
import { SaveOptions } from 'src/lib/EntityContainer';
import { Nullable } from 'src/lib/generics';
import { laterThanValidator } from 'src/lib/genericValidators';
import { compareDates, getTodayUTC, markFormGroupTouched, weightFormat } from 'src/lib/helperFunctions';
import {
  City,
  CommonCities,
  CommonCurrencies,
  Contact,
  ContractLine,
  ContractType,
  CreateContractLineRequest,
  CreateDraftClauseRequest,
  CreateDraftLineRequest,
  CreatePhysicalDraftRequest,
  Currency,
  DraftLine,
  LinePriceType,
  OceanRateLookupData,
  PhysicalContract,
  PhysicalDraft,
  PhysicalDraftClause,
  Unit,
  UpdateDraftClauseRequest,
  UpdatePhysicalContractRequest,
  UpdatePhysicalDraftRequest,
  UserGroup,
} from 'src/lib/newBackendTypes';
import { BudgetElementType } from 'src/lib/newBackendTypes/budgetElement';
import { Clause } from 'src/lib/newBackendTypes/clause';
import { ClauseTemplate, ClauseType } from 'src/lib/newBackendTypes/clauseTemplate';
import { ContainerType } from 'src/lib/newBackendTypes/containerType';
import {
  AdvanceTypes,
  BasePhysicalContractRequest,
  ClauseMissingInformationEmailRequest,
  ClauseTypes,
  ContractClass,
  ContractKeyword,
  ContractLineRestrictions,
  ContractRestrictions,
  ContractTypes,
  KeywordTypes,
} from 'src/lib/newBackendTypes/contract';
import { PhysicalContractExpense } from 'src/lib/newBackendTypes/contractExpense';
import { ContractFormPricing, ContractFormPricingToContractPricing, ContractFormPricingToUpdateContractPricing, ContractPricingToContractFormPricing } from 'src/lib/newBackendTypes/contractPricing';
import { PhysicalDraftExpense } from 'src/lib/newBackendTypes/draftExpense';
import { CreateDraftKeywordRequest, DraftKeyword, UpdateDraftKeywordRequest } from 'src/lib/newBackendTypes/draftKeyword';
import { YN } from 'src/lib/newBackendTypes/enums';
import { TermGroups, PaymentTerm } from 'src/lib/newBackendTypes/paymentTerm';
import { Product } from 'src/lib/newBackendTypes/product';
import { Requirement } from 'src/lib/newBackendTypes/requirement';
import { TypedFormArray, TypedFormGroup } from 'src/lib/typedForms';
import { BookingIcon, CommentsIcon, ContractIcon, DocumentsIcon, InvoiceIcon, ShipmentIcon, TasksIcon, UserGroupsEnum } from 'src/lib/uiConstants';
import { ClauseEmailPrefill, ClauseMissingInformationEmailComponent } from '../clause-missing-information-email/clause-missing-information-email.component';
import { CustomDialogResult } from 'src/app/core/services/selector-popup.service';
import { getGraphqlRequestQuery } from 'src/lib/graphql/graphQlFunctions';
import { ExistingGraphql, GraphqlNames } from 'src/lib/graphql/graphQlEnums';
import { basicCityGraphqlRequest } from 'src/lib/graphql/entities/city.graphql';

@UntilDestroy()
@Directive()
export abstract class BaseContractContainer extends EntityContainer<PhysicalContract | PhysicalDraft, CreatePhysicalDraftRequest | never, UpdatePhysicalContractRequest | UpdatePhysicalDraftRequest> {
  @ViewChild('companyDropdown', { static: false })
  companyDropdown: SimpleDropdownComponent;

  private autoAddExpense = true;
  percentageFormat: NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits: 4,
  };
  emFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 4,
    minimumFractionDigits: 2,
  };
  dollarFormat = dollarFormat();
  public totalQuantity: string | null = null;
  public contractValue: string | null = null;
  public contractAmount: number | null = null;
  public lineTotals: { [id: number]: string } = {};
  public contractLines: { [lineNumber: number]: { quantity: number; priceType: string } } = {};

  public quantityUnit: Unit | null;
  premiumUnit: Unit | null;
  priceUnit: Unit | null;
  currency: Currency | null;

  someRandomValue = new UntypedFormControl();

  public selectedTemplate: UntypedFormControl = new UntypedFormControl();
  public selectedLCTemplate: UntypedFormControl = new UntypedFormControl();
  public clauseTemplates: ClauseTemplate[];
  public lcClauseTemplates: ClauseTemplate[];

  public advanceAmount: number | null = null;
  public advancePercentage: number | null = null;

  contractTypes = ContractTypes;

  advanceTypes: { value: AdvanceTypes; label: string }[] = [
    { value: AdvanceTypes.AMOUNT, label: 'Amount' },
    { value: AdvanceTypes.PERCENTAGE, label: 'Percentage' },
    { value: AdvanceTypes.NO_ADVANCE, label: 'No Advance' },
  ];

  classData: { label: string; value: ContractClass }[] = [
    {
      label: 'No Hedge',
      value: ContractClass.NH,
    },
    {
      label: 'Open Hedge',
      value: ContractClass.H,
    },
    {
      label: 'QP Hedge',
      value: ContractClass.QP,
    },
  ];

  clauseTypes: { label: string; value: ClauseType }[] = [
    {
      label: 'General',
      value: ClauseType.GENERAL,
    },
    {
      label: 'LC',
      value: ClauseType.LC,
    },
  ];

  ynData: { label: string; value: YN }[] = [
    {
      label: 'Yes',
      value: YN.Y,
    },
    {
      label: 'No',
      value: YN.N,
    },
  ];

  byPackingOptions: { label: string; value: YN }[] = [
    {
      label: 'Per Container',
      value: YN.Y,
    },
    {
      label: 'Per Unit',
      value: YN.N,
    },
  ];

  private requiredIfDraft = (control: AbstractControl) => (this.isDraftContract() ? Validators.required(control) : null);

  private collateralValidator = (control: AbstractControl) => {
    if (!this.form) return null;
    if (this.form.value.type !== ContractType.SALE || this.isDraftContract()) return null;
    return Validators.required(control);
  };

  public expandGeneral: boolean = false;
  public expandLines: boolean = false;
  public expandExpenses: boolean = false;
  public expandRequirements: boolean = false;
  public expandClauses: boolean = false;
  public expandComments: boolean = false;
  public expandApprovals: boolean = false;
  public expandUnits: boolean = false;

  form: TypedFormGroup<ContractForm> = null;

  hasFixations = false;
  hasShipments = false;
  hasInvoices = false;
  hasAdvances = false;
  linesRestrictions: ContractLineRestrictions[];

  hasBookingRequests = false;

  ContractIcon = ContractIcon;
  CommentsIcon = CommentsIcon;
  TasksIcon = TasksIcon;
  DocumentsIcon = DocumentsIcon;
  ShipmentIcon = ShipmentIcon;
  InvoiceIcon = InvoiceIcon;
  BookingIcon = BookingIcon;

  EntityIcon: string;

  // session user info
  user: Contact | null = null;
  currentUserGroups: UserGroup[];

  listSources: { [name: string]: DropdownConfig<any> } = {
    traderDropdown: traderDropdown(),
    contactDropdown: contactDropdown(),
    counterpartyDropdown: counterpartyDropdown(),
    paymentTerm: new DropdownConfig<PaymentTerm>(endpoints.listPaymentTerms, 'name', 'id', null, {
      authorizedUseOnly: YN.N,
      archived: YN.N,
    }), // not locked
    containerType: new DropdownConfig<ContainerType>(endpoints.listContainerTypes, 'name', 'id'),
    budgetElement: budgetElementDropdown(),
    requirements: new DropdownConfig<Requirement>(endpoints.listRequirements, 'name', 'id'),
    clauseTemplates: new DropdownConfig<ClauseTemplate>(endpoints.listClauseTemplates, 'name', 'id', null, { typeId: ClauseType.GENERAL }),
    clauseTemplatesLc: new DropdownConfig<ClauseTemplate>(endpoints.listClauseTemplates, 'name', 'id', null, { typeId: ClauseType.LC }),
  };

  get expenseAuthorized() {
    return this.authorized[endpoints.listBudgetElements];
  }

  get bookingRequestsAuthorized() {
    return this.authorized[endpoints.listSTBBookingData];
  }

  get oceanRatesAuthorized() {
    return this.authorized[endpoints.oceanRateLookup];
  }

  get listContractFixationDetailsAuthorized() {
    return this.authorized[endpoints.listContractFixationDetails];
  }

  get isQPHedge() {
    return this.form?.get('class')?.value === ContractClass.QP;
  }

  constructor(
    options: EntityFormOptions<PhysicalContract | PhysicalDraft>,
    route: ActivatedRoute,
    delegateService: DelegateService,
    public commonDataService: CommonDataService,
    private businessLogicService: BusinessLogicService,
    elementRef: ElementRef,
    zone: NgZone,
    protected promptService: PromptService
  ) {
    super(options, route, elementRef, zone, delegateService);
    this.user = this.store.snapshot((state) => state.user.user);
    this.currentUserGroups = this.store.snapshot((state) => state.user.userGroups);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initializeForm() {
    this.form = new TypedFormGroup<ContractForm>({
      id: new UntypedFormControl(),
      trader: new UntypedFormControl(null, this.requiredIfDraft),
      company: new UntypedFormControl(null, this.requiredIfDraft),
      type: new UntypedFormControl(null, this.requiredIfDraft),
      counterparty: new UntypedFormControl(null, this.requiredIfDraft),
      agent: new UntypedFormControl(),
      product: new UntypedFormControl(null, this.requiredIfDraft),
      incoterm: new UntypedFormControl(null, this.requiredIfDraft),
      paymentTerm: new UntypedFormControl(null),
      lcInterestIncluded: new UntypedFormControl(YN.N),
      advanceValue: new UntypedFormControl(null, [this.advanceValueValidator()]),
      advanceType: new UntypedFormControl(null, this.requiredIfDraft),
      counterpartyReference: new UntypedFormControl('', Validators.maxLength(30)),
      class: new UntypedFormControl(null, this.requiredIfDraft),
      date: new UntypedFormControl(getTodayUTC(), [this.conditionalDateValidator(null), this.requiredIfDraft]),
      tolerance: new UntypedFormControl(null, this.requiredIfDraft),
      quantityUnitId: new UntypedFormControl(null, this.requiredIfDraft),
      currencyId: new UntypedFormControl(null, this.requiredIfDraft),
      priceUnitId: new UntypedFormControl(null, this.requiredIfDraft),
      premiumUnitId: new UntypedFormControl(),
      expectedNumberOfLoads: new UntypedFormControl(null, [this.numberOfLoadsHardRange(), this.requiredIfDraft]),
      useMWTI: new UntypedFormControl(YN.N),
      mwti: new UntypedFormControl(null),
      clauses: new UntypedFormArray([], this.clauseValidator()),
      comments: new UntypedFormControl([]),
      estimatedMargin: new UntypedFormControl(null, Validators.required),
      approvals: new UntypedFormControl([]),
      lines: new UntypedFormArray([]),
      expenses: new UntypedFormArray([]),
      documents: new UntypedFormControl([]),
      shipmentLock: new UntypedFormControl(YN.N),
      destinationId: new UntypedFormControl(null),
      incotermPlaceId: new UntypedFormControl(null),
      collateral: new UntypedFormControl(null, [this.collateralValidator]),
      archived: new UntypedFormControl(null),
      archivingDate: new UntypedFormControl(null),
    });

    if (this.expenseAuthorized) this.addExpense();
    this.addLine();
  }

  public initializeFormListeners() {
    this.form
      .get('advanceType')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.form.get('advanceValue').updateValueAndValidity();
      });

    this.form
      .get('type')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((type: ContractType) => {
        if (!this.entity && type === ContractType.PURCHASE) this.form.patchValue({ tolerance: 2 });
      });

    this.form
      .get('product')
      .valueChanges.pipe(untilDestroyed(this), startWith(this.form.value.product), pairwise())
      .subscribe(([prev, curr]: [Product | null, Product | null]) => {
        let lines = <UntypedFormArray>this.form.get('lines');
        if (!!prev && (!curr || prev.productId !== curr.productId)) {
          for (let line of lines.controls) {
            line.get('item').setValue(null);
            line.get('clientItem').setValue(null);
          }
        }
      });

    const unitSubscriber = ([unitId, units]: [number | null, Unit[]]) => {
      if (!unitId || !units) return null;
      else {
        return units.find((u) => u.unitId === unitId) || null;
      }
    };
    const currencySubscriber = ([currencyId, currencies]: [number | null, Currency[]]) => {
      if (!currencyId || !currencies) return null;
      else {
        return currencies.find((c) => c.id === currencyId) || null;
      }
    };

    combineLatest([this.form.get('quantityUnitId').valueChanges.pipe(startWith(this.form.value.quantityUnitId)), this.commonDataService.staticFilteredUnits])
      .pipe(untilDestroyed(this), map(unitSubscriber))
      .subscribe((unit: Unit | null) => {
        this.quantityUnit = unit;
        if (this.entity && this.entity.quantityUnitId !== unit?.unitId) {
          this.totalQuantity = null;
          this.contractValue = null;
          this.contractAmount = null;
          this.lineTotals = {};
        }
      });
    combineLatest([this.form.get('priceUnitId').valueChanges.pipe(startWith(this.form.value.priceUnitId)), this.commonDataService.staticFilteredUnits])
      .pipe(untilDestroyed(this), map(unitSubscriber))
      .subscribe((unit: Unit | null) => {
        this.priceUnit = unit;
        if (this.entity && this.entity.priceUnitId !== unit?.unitId) {
          this.totalQuantity = null;
          this.contractValue = null;
          this.contractAmount = null;
          this.lineTotals = {};
        }
      });
    combineLatest([this.form.get('premiumUnitId').valueChanges.pipe(startWith(this.form.value.premiumUnitId)), this.commonDataService.staticFilteredUnits])
      .pipe(untilDestroyed(this), map(unitSubscriber))
      .subscribe((unit: Unit | null) => {
        this.premiumUnit = unit;
        if (this.entity && this.entity.premiumUnitId !== unit?.unitId) {
          this.totalQuantity = null;
          this.contractValue = null;
          this.contractAmount = null;
          this.lineTotals = {};
        }
      });
    combineLatest([this.form.get('currencyId').valueChanges.pipe(startWith(this.form.value.currencyId)), this.commonDataService.staticCurrencies])
      .pipe(untilDestroyed(this), map(currencySubscriber))
      .subscribe((currency: Currency | null) => {
        this.currency = currency;
        if (this.entity && this.entity.currencyId !== currency?.id) {
          this.totalQuantity = null;
          this.contractValue = null;
          this.contractAmount = null;
          this.lineTotals = {};
        }
      });

    // Find contract and contract line restrictions to disable form fields
    if (!this.isDraftContract()) {
      this.api.rpc<ContractRestrictions>(endpoints.getContractRestrictions, { id: this.entity.id }, null).subscribe((res: ContractRestrictions) => {
        this.hasShipments = res.hasShipments;
        this.hasFixations = res.hasFixations;
        this.hasInvoices = res.hasInvoices;
        this.hasAdvances = res.hasAdvances;
        this.linesRestrictions = res.lines;
      });
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (this.form && !this.entity && this.companyDropdown) {
      setTimeout(() => {
        this.companyDropdown.focus();
      });
    }
  }

  public contractDateValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (this.form.get('date').value === null || control.value === null) return;
      let cDate, date;
      try {
        cDate = new Date(this.form.get('date').value);
        date = new Date(control.value);
      } catch {
        cDate = null;
        date = null;
      }
      if (cDate) cDate.setUTCHours(0, 0, 0, 0);
      if (date) date.setUTCHours(0, 0, 0, 0);
      return !!cDate && !!date && cDate > date ? { afterContractDate: true } : null;
    };
  }

  clickAddLine() {
    let line = this.addLine();
    line.markAsDirty();
    this.contractValue = null;
    this.contractAmount = null;
    this.totalQuantity = null;
  }

  public addLine() {
    const control = <UntypedFormArray>this.form.controls['lines'];
    let formGroup = new TypedFormGroup<ContractLineForm>({
      id: new UntypedFormControl(),
      shipmentPeriodStart: new UntypedFormControl(null, [this.contractDateValidator()]),
      shipmentPeriodEnd: new UntypedFormControl(null),
      shipmentPeriodType: new UntypedFormControl(null),
      quantity: new UntypedFormControl(),
      containerTypes: new UntypedFormControl(),
      originCountry: new UntypedFormControl(),
      destination: new UntypedFormControl(),
      incotermPlace: new UntypedFormControl(),
      item: new UntypedFormControl(),
      clientItem: new UntypedFormControl(),
      pricing: new UntypedFormControl(),
      priceType: new UntypedFormControl(),
      lineNumber: new UntypedFormControl(),
      metalPercentage: new UntypedFormControl(),
      metalControlGroup: new UntypedFormControl(),
      recoveryPercentage: new UntypedFormControl(),
      usesRecoveryPercentage: new UntypedFormControl(YN.N),
      finished: new UntypedFormControl(YN.N),
    });
    control.push(formGroup);

    formGroup.get('shipmentPeriodEnd').setValidators(this.shipmentEndValidator(formGroup.get('shipmentPeriodStart')));

    formGroup.get('usesRecoveryPercentage').setValidators((c: AbstractControl) => {
      if (!this.form.value.product || this.form.value.product.recoveryPercentageEnabled !== YN.Y) return null;

      return Validators.required(c);
    });

    formGroup.get('recoveryPercentage').setValidators((c: AbstractControl) => {
      if (!this.form.value.product || this.form.value.product.recoveryPercentageEnabled !== YN.Y || formGroup.value.usesRecoveryPercentage !== YN.Y) return null;

      return Validators.required(c);
    });

    combineLatest([formGroup.get('quantity').valueChanges, formGroup.get('pricing').valueChanges, formGroup.get('priceType').valueChanges])
      .pipe(untilDestroyed(this))
      .subscribe(([quantity, price, priceType]) => {
        if (formGroup.value.lineNumber) {
          let linePriceType = '';
          switch (priceType) {
            case LinePriceType.FIXED:
              linePriceType = 'Fixed';
              break;
            case LinePriceType.FORMULA:
              linePriceType = 'Formula';
              break;

            default:
              break;
          }
          this.contractLines[formGroup.value.lineNumber] = {
            quantity: quantity,
            priceType: linePriceType,
          };
        }
        if (price['firstChange']) return;

        if (formGroup.value.id) this.lineTotals[formGroup.value.id] = null;
        this.contractValue = null;
        this.contractAmount = null;
        this.totalQuantity = null;
      });

    return formGroup;
  }

  public removeLine(i: number) {
    if (!this.deleteLineAuthorized) return;
    const arr = <UntypedFormArray>this.form.controls['lines'];
    let line: ContractLineForm = arr.get([i]).value;

    if ((this.form.value.lines.filter((l) => !!l.id).length === 1 && !!line.id) || (!this.form.value.id && this.form.value.lines.length === 1)) {
      this.dialogService.open({
        title: 'Delete Failed',
        content: 'You cannot delete the only existing line',
      });
      return;
    }

    this.dialogService
      .open({
        title: 'Delete Line',
        content: 'Are you sure you wish to delete this line? This cannot be undone',
        actions: [
          {
            text: 'Cancel',
          },
          {
            text: 'Delete',
            themeColor: 'primary',
            primary: true,
          },
        ],
      })
      .result.pipe(
        filter((res) => !(res instanceof DialogCloseResult) && res.text === 'Delete'),
        switchMap(() => {
          if (!!line.id) {
            const rId = this.spinnerService.startRequest('Deleting Line');
            const endpoint = this.entityName === 'Contract' ? endpoints.deleteContractLine : endpoints.deleteDraftLine;
            return this.api.rpc<DraftLine | ContractLine>(endpoint, { filters: { id: line.id } }, null).pipe(
              tap((_) => {
                this.spinnerService.completeRequest(rId);
              }),
              map((res) => {
                this.clickSave();
                return !!res;
              })
            );
          } else return of(true);
        })
      )
      .subscribe((res: boolean) => {
        if (res) {
          arr.removeAt(i);
        }
      });
  }

  public addExpense(expense?: Partial<ContractExpenseForm>): UntypedFormGroup {
    const formGroup = new TypedFormGroup<ContractExpenseForm>({
      id: new UntypedFormControl(),
      budgetElement: new UntypedFormControl(),
      amount: new UntypedFormControl(),
      notes: new UntypedFormControl(),
      mandatory: new UntypedFormControl(YN.N),
      userAdded: new UntypedFormControl(YN.Y),
      contact: new UntypedFormControl(),
      currencyId: new UntypedFormControl(),
      byPacking: new UntypedFormControl(),
      oceanRateSegmentId: new UntypedFormControl(),
      rateRequested: new UntypedFormControl(YN.N),
    });
    const expenses: UntypedFormArray = <UntypedFormArray>this.form.controls['expenses'];

    if (!!expense) {
      formGroup.patchValue(expense, { emitEvent: false });
      expenses.insert(0, formGroup);
    } else {
      expenses.push(formGroup);
    }

    formGroup.get('budgetElement').setValidators(this.requiredExpenseValidator(formGroup));
    formGroup.get('amount').setValidators([this.requiredExpenseValidator(formGroup), this.expenseAmountValidator(formGroup.get('budgetElement'))]);
    formGroup.get('currencyId').setValidators(this.requiredExpenseValidator(formGroup));
    formGroup.get('byPacking').setValidators(this.requiredExpenseValidator(formGroup));
    formGroup.get('contact').setValidators((control) => {
      if (formGroup.value.budgetElement && formGroup.value.budgetElement.budgetElementType === BudgetElementType.COMMISSION_ON_PURCHASES) {
        return Validators.required(control);
      }
      if (formGroup.value.budgetElement && formGroup.value.budgetElement.budgetElementType === BudgetElementType.COMMISSION_ON_SALES) {
        return Validators.required(control);
      }
      return null;
    });

    formGroup.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => expenses.length > 0),
        filter(() => this.autoAddExpense),
        map(() => expenses.controls[expenses.length - 1]),
        filter((lastExpense) => !!lastExpense),
        map((lastExpense) => lastExpense.value),
        filter((lastExpense: ContractExpenseForm) => !!lastExpense.budgetElement || !!lastExpense.amount || !!lastExpense.byPacking || !!lastExpense.currencyId)
      )
      .subscribe((v) => {
        this.addExpense();
      });

    formGroup
      .get('amount')
      .valueChanges.pipe(untilDestroyed(this), debounceTime(300))
      .subscribe(() => {
        if (formGroup.get('amount').dirty) formGroup.get('amount').updateValueAndValidity();
      });

    //Be ready to automatically add an expense if user edits last expense
    this.autoAddExpense = this.saveAuthorized && this.expenseAuthorized;

    return formGroup;
  }

  public removeExpense(i: number) {
    const arr = <UntypedFormArray>this.form.controls['expenses'];

    this.dialogService
      .open({
        title: 'Delete Line',
        content: 'Are you sure you wish to delete this line? This cannot be undone',
        actions: [
          {
            text: 'Cancel',
          },
          {
            text: 'Delete',
            themeColor: 'primary',
            primary: true,
          },
        ],
      })
      .result.pipe(
        filter((res) => !(res instanceof DialogCloseResult) && res.text === 'Delete'),
        switchMap(() => {
          let expense: PhysicalDraftExpense = arr.get([i]).value;
          if (!this.deleteExpenseAuthorized && !!expense.id) return of(false);
          if (!!expense.id && expense.mandatory !== YN.Y) {
            let rId = this.spinnerService.startRequest('Deleting Expense');
            let endpoint = this.entityName === 'Contract' ? endpoints.deleteContractExpense : endpoints.deleteDraftExpense;
            return this.api.rpc<DraftLine>(endpoint, { filters: { id: expense.id } }, null).pipe(
              tap((_) => {
                this.spinnerService.completeRequest(rId);
              }),
              map((res) => !!res)
            );
          } else return of(true);
        })
      )
      .subscribe((res: boolean) => {
        if (res) {
          arr.removeAt(i);
        }
      });
  }

  public autopopulateTolerance(product: Product | null, type: ContractType | null) {
    if (product === undefined || product === null || type === undefined || type === null) return;

    let tolerance = this.form.controls.tolerance;
    if (type == ContractType.PURCHASE) tolerance.setValue(product.purchaseTolerance);
    if (type == ContractType.SALE) tolerance.setValue(product.saleTolerance);
  }

  get isSale() {
    return this.form.value.type === ContractType.SALE;
  }

  public parse(input) {
    return JSON.stringify(input, null, 4);
  }

  public get paymentTermIsLC(): boolean {
    return !!this.form.value.paymentTerm && this.form.value.paymentTerm.groupId === TermGroups.LC;
  }

  protected get handoverBeforeShipping(): boolean {
    const selectedIncoterm = this.form.controls.incoterm.value;
    const selectedContractType = this.form.controls.type.value;
    if (!selectedIncoterm) return false;

    return selectedIncoterm.carriage === 'B' && selectedContractType === ContractType.PURCHASE;
  }

  public numberOfLoadsHardRange(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!this.form || control.value === null) return null;
      let { max, min } = this.businessLogicService.getNumberOfLoadsRange(this.form.value);

      return (!max || control.value <= max) && (!min || control.value >= min) ? null : { outOfRange: { min, max } };
    };
  }

  sendMissingInformationEmail() {
    const selector = this.delegate.getService('selector');

    selector
      .openForm<ClauseMissingInformationEmailRequest, ClauseMissingInformationEmailComponent, ClauseEmailPrefill>(ClauseMissingInformationEmailComponent, {
        title: 'Clause Missing Information Email',
        prefillValue: {
          contractNumber: this.entity.number,
          traderId: this.entity.trader.id,
        },
        maxWidth: '500px',
      })
      .subscribe((res) => {
        if (res === 'Close') return;
        const request: ClauseMissingInformationEmailRequest = {
          contractNumber: res.contractNumber,
          emailTo: res.emailTo,
          message: res.message,
        };
        let rid = this.spinnerService.startRequest('Sending Email');
        const prompt = this.delegate.getService('prompt');
        const emailResponse = this.api.run<ClauseMissingInformationEmailRequest>(endpoints.sendClauseMissingInformationEmail, request, null);
        if (emailResponse) {
          this.spinnerService.completeRequest(rid);
          return prompt.htmlDialog('Success', `<div style="white-space: pre">Email succesfully sent.</div>`);
        }
        return prompt.htmlDialog('Error', `<div style="white-space: pre">Error sending email. Please try again.</div>`);
      });
  }

  addClause() {
    let arr = <UntypedFormArray>this.form.get('clauses');

    let fg = new TypedFormGroup<Pick<Clause, 'clauseTemplate' | 'clauseTemplateId' | 'id' | 'text' | 'type'>>({
      clauseTemplate: new UntypedFormControl(),
      clauseTemplateId: new UntypedFormControl(),
      id: new UntypedFormControl(),
      text: new UntypedFormControl(null, Validators.required),
      type: new UntypedFormControl(null, [Validators.required, this.clauseTypeValidator()]),
    });

    fg.get('clauseTemplate')
      .valueChanges.pipe(untilDestroyed(this), startWith('First'), pairwise())
      .subscribe(([ct1, ct2]: [ClauseTemplate | null | 'First', ClauseTemplate | null]) => {
        if (ct1 && ct2 && typeof ct1 === 'object' && ct1.id === ct2.id) return;

        if (ct2 !== null && ct2 !== undefined && !this.loadingEntity) {
          fg.patchValue({ text: ct2.clauseText });
        }
      });

    arr.push(fg);
  }

  removeClause(i: number) {
    let arr = <UntypedFormArray>this.form.get('clauses');
    let clause: Clause = arr.get([i]).value;
    if (!this.deleteClauseAuthorized && !!clause?.id) return;

    this.dialogService
      .open({
        title: 'Delete Clause',
        content: 'Are you sure you wish to delete this clause? This cannot be undone',
        actions: [
          {
            text: 'Cancel',
          },
          {
            text: 'Delete',
            themeColor: 'primary',
            primary: true,
          },
        ],
      })
      .result.pipe(
        filter((res) => !(res instanceof DialogCloseResult) && res.text === 'Delete'),
        switchMap(() => {
          if (!!clause.id) {
            let rId = this.spinnerService.startRequest('Deleting Clause');
            const endpoint = this.isDraftContract() ? endpoints.deleteDraftClause : endpoints.deleteContractClause;
            return this.api.rpc<Clause>(endpoint, { filters: { id: clause.id } }, null).pipe(
              tap((_) => {
                this.spinnerService.completeRequest(rId);
              }),
              map((res) => !!res)
            );
          } else return of(true);
        })
      )
      .subscribe((res: boolean) => {
        if (res) {
          arr.removeAt(i);
        }
      });
  }

  public removeRequirement(ev) {
    if (ev.dataItem.mandatory === 'Y') {
      ev.prevented = true;
    }
  }

  hardReloadForm(entity) {
    this.hasBookingRequests = false;
    super.hardReloadForm(entity);
  }

  public async loadEntity(draft: PhysicalDraft | PhysicalContract) {
    this.contractLines = {};
    this.quantityUnit = null;
    let form = this.formFromEntity(draft);

    this.entity.id = draft.id;
    if (form) {
      if (form.lines && form.lines.length > 0) {
        for (let _ in form.lines) {
          this.addLine();
        }
      } else {
        this.addLine();
      }
      for (let i = 0; i < form.expenses.length; i++) {
        this.addExpense();
      }
      for (let i = 0; i < form.clauses.length; i++) {
        this.addClause();
      }

      this.form.patchValue({
        ...form,
        lines: (form.lines || []).map((l) => ({
          ...l,
          usesRecoveryPercentage: !!l.recoveryPercentage ? YN.Y : YN.N,
        })),
      });

      markFormGroupTouched(this.form);
      this.form.markAsPristine();
      this.form.markAsUntouched();

      this.expandGeneral = this.expandLines = this.expandExpenses = this.expandRequirements = this.expandClauses = this.expandComments = this.expandApprovals = this.expandUnits = true;

      let existingDate = this.entity.date;

      this.form.get('date').setValidators(this.conditionalDateValidator(existingDate));

      const unit = this.commonDataService.staticUnits.value.find((u) => u.unitId === draft.quantityUnitId);

      this.totalQuantity = unit ? `${weightFormat(draft.quantity)} ${unit.code}` : null;
    }
  }

  //date validators - August 27th 2020, agreed upon this method with Ariel and Linus
  conditionalDateValidator(existingValue?: string | Date) {
    let date: Date;
    try {
      date = new Date(existingValue);
    } catch (e) {
      console.log(e);
      date = null;
    }
    return (control: AbstractControl) => {
      if (typeof control.value === 'string') return null;

      let value: Date | null;
      try {
        value = new Date(control.value);
      } catch (e) {
        console.log(e);
        value = null;
      }
      if (value === null) return;
      if (!this.entity) return laterThanValidator(getTodayUTC(), 'Date must be today or later')(control);
      if (!!date && !compareDates(date, value)) {
        return laterThanValidator(getTodayUTC(), 'Date must be today or later')(control);
      }
      return null;
    };
  }

  qpDateValidator(typeControl: AbstractControl): ValidatorFn {
    return (control: UntypedFormControl) => {
      return typeControl.value === 'F' && this.isDraftContract() ? laterThanValidator(getTodayUTC(), 'Date must be today or later')(control) : null;
    };
  }

  clauseValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!this.form) return null;
      let paymentTerm: PaymentTerm | null = this.form.value.paymentTerm;
      if (!paymentTerm || paymentTerm.groupId !== TermGroups.LC) return null;
      let val: Nullable<Clause>[] = control.value;
      if (!val) return null;
      return val.some((clause) => {
        clause.type === ClauseTypes.LC;
      })
        ? { lcRequired: true }
        : null;
    };
  }

  public formFromEntity(contract: PhysicalDraft | PhysicalContract) {
    let lines: (DraftLine | ContractLine)[] = contract.lines;
    let expenses: (PhysicalDraftExpense | PhysicalContractExpense)[] = contract.expenses;
    let clauses: (PhysicalDraftClause | Clause)[] = contract.clauses;
    let keywords: (ContractKeyword | DraftKeyword)[] = contract.keywords;

    let lcKeyword = (keywords || []).find((k) => k.keywordType === KeywordTypes.LC_INTEREST_INCLUDED);
    let mwtiKeyword = (keywords || []).find((k) => k.keywordType === KeywordTypes.MWTI_USED);
    let mwti: number = null;

    if (mwtiKeyword && !!mwtiKeyword.keywordValue) {
      try {
        mwti = Number(mwtiKeyword.keywordValue);
      } catch (e) {
        mwti = null;
      }
    }

    let form = {
      ...contract,
      lines: lines.map((line) => {
        return {
          ...line,
          pricing: ContractPricingToContractFormPricing(line),
        };
      }),
      paymentTerm: contract.paymentTerm ? contract.paymentTerm : null,
      incoterm: !!contract.incotermId ? contract.incoterm : null,
      company: !!contract.companyId ? contract.company : null,
      counterparty: !!contract.counterpartyId ? contract.counterparty : null,
      expenses: expenses || [],
      clauses: clauses || [],
      requirements: [],
      lcInterestIncluded: <YN | null>(lcKeyword ? lcKeyword.keywordValue : null),
      useMWTI: mwtiKeyword ? YN.Y : YN.N,
      mwti,
    };
    return form;
  }

  getCreateContractLineRequests(form: ContractForm): CreateContractLineRequest[] | CreateDraftLineRequest[] {
    let lines = form.lines || [];
    return lines.map((line) => {
      return {
        ...getBaseLineRequest(line),
        metalPercentage: form.class === ContractClass.QP || form.class === ContractClass.H ? line.metalPercentage : 100,
        incotermId: form.incoterm ? form.incoterm.id : 0,
        ...ContractFormPricingToContractPricing(line.pricing, form.class),
      };
    });
  }

  getUpdateContractLineRequests(form: ContractForm) {
    let lines = form.lines || [];

    return lines.map((line) => {
      if (line.id)
        return {
          id: line.id,
          ...getBaseLineRequest(line),
          metalPercentage: form.class === ContractClass.QP || form.class === ContractClass.H ? line.metalPercentage : 100,
          incotermId: form.incoterm ? form.incoterm.id : 0,
          ...ContractFormPricingToUpdateContractPricing(line.pricing, form.class),
        };
      return {
        ...getBaseLineRequest(line),
        incotermId: form.incoterm ? form.incoterm.id : 0,
        metalPercentage: form.class === ContractClass.QP || form.class === ContractClass.H ? line.metalPercentage : 100,
        ...ContractFormPricingToContractPricing(line.pricing, form.class),
      };
    });
  }

  getBaseRequest(form: ContractForm): BasePhysicalContractRequest {
    return {
      class: form.class,
      companyId: form.company ? form.company.id : null,
      traderId: form.trader ? form.trader.id : null,
      counterpartyId: form.counterparty ? form.counterparty.id : null,
      counterpartyReference: form.counterpartyReference ? form.counterpartyReference : '',
      date: form.date,
      productId: form.product ? form.product.productId : null,
      incotermId: form.incoterm ? form.incoterm.id : null,
      paymentTermId: form.paymentTerm ? form.paymentTerm.id : null,
      advanceValue: form.advanceType && form.advanceType !== AdvanceTypes.NO_ADVANCE ? form.advanceValue : null,
      advanceType: form.advanceType,
      tolerance: form.tolerance,
      estimatedMargin: form.estimatedMargin,
      quantityUnitId: form.quantityUnitId,
      expectedNumberOfLoads: form.expectedNumberOfLoads > 0 ? form.expectedNumberOfLoads : null,
      type: form.type,
      archived: YN.N,
      currencyId: form.currencyId,
      priceUnitId: form.priceUnitId,
      premiumUnitId: form.premiumUnitId,
      shipmentLock: form.shipmentLock,
      collateral: form.collateral,
    };
  }

  getUpdateContractKeywords(form: ContractForm) {
    let keywords: (CreateDraftKeywordRequest | UpdateDraftKeywordRequest)[] = (this.entity.keywords || []).map((k) => {
      return { keywordValue: k.keywordValue, keywordType: k.keywordType, id: k.id };
    });
    let lcInterestIncluded = this.paymentTermIsLC ? form.lcInterestIncluded : null;
    let useMWTI = form.useMWTI || YN.N;
    let mwti = form.mwti;
    let lcIndex = keywords.findIndex((k) => k.keywordType === KeywordTypes.LC_INTEREST_INCLUDED);
    let mwtiIndex = keywords.findIndex((k) => k.keywordType === KeywordTypes.MWTI_USED);
    if (lcIndex >= 0) {
      if (lcInterestIncluded !== null) {
        keywords[lcIndex].keywordValue = form.lcInterestIncluded;
      } else {
        keywords.splice(lcIndex, 1);
      }
    } else {
      if (lcInterestIncluded !== null) {
        keywords.push({
          keywordValue: form.lcInterestIncluded,
          keywordType: KeywordTypes.LC_INTEREST_INCLUDED,
        });
      }
    }

    if (mwtiIndex >= 0) {
      if (useMWTI === YN.Y) {
        keywords[mwtiIndex].keywordValue = `${mwti || ''}`;
      } else {
        keywords.splice(mwtiIndex, 1);
      }
    } else {
      if (useMWTI === YN.Y) {
        keywords.push({ keywordValue: `${form.mwti || ''}`, keywordType: KeywordTypes.MWTI_USED });
      }
    }
    return keywords;
  }

  getBaseUpdateEntityRequest(form: ContractForm) {
    const contract: UpdatePhysicalContractRequest | UpdatePhysicalDraftRequest = {
      id: this.entity.id,
      ...this.getBaseRequest(form),
      keywords: this.getUpdateContractKeywords(form),
      expenses: (form.expenses || [])
        .filter((e) => !!e.amount || !!e.budgetElement || !!e.id)
        .map((e) => {
          const baseRequest = {
            budgetElementId: e.budgetElement ? e.budgetElement.id : null,
            notes: e.notes || '',
            contactId: e.contact ? e.contact.id : null,
            amount: e.amount,
            currencyId: e.currencyId,
            userAdded: e.userAdded,
            mandatory: e.mandatory,
            byPacking: e.byPacking,
            oceanRateSegmentId: e.oceanRateSegmentId,
            rateRequested: e.rateRequested,
          };
          if (e.id) return { ...baseRequest, id: e.id };
          else return baseRequest;
        }),
      clauses: (form.clauses || []).map((c) => {
        let baseRequest: CreateDraftClauseRequest | UpdateDraftClauseRequest;
        if (c.clauseTemplate && c.clauseTemplate.clauseText === c.text) {
          baseRequest = {
            clauseTemplateId: c.clauseTemplate.id,
            type: c.type,
          };
        } else {
          baseRequest = {
            text: c.text,
            clauseTemplateId: c.clauseTemplate ? c.clauseTemplate.id : null,
            type: c.type,
          };
        }
        if (c.id) return { ...baseRequest, id: c.id };
        return baseRequest;
      }),
    };

    return contract;
  }

  setClauseTemplates() {
    let clauses = <UntypedFormArray>this.form.get('clauses');

    for (let fg of clauses.controls) {
      if (!fg.value.id) continue;
      let id = fg.value.clauseTemplateId;
      let gTemplate = this.clauseTemplates.find((gc) => gc.id === id);
      if (gTemplate) {
        fg.patchValue({ clauseTemplate: gTemplate }, { emitEvent: false });
        continue;
      }
      let lcTemplate = this.lcClauseTemplates.find((lc) => lc.id === id);
      if (lcTemplate) {
        fg.patchValue({ clauseTemplate: lcTemplate }, { emitEvent: false });
      }
    }
  }

  clickCopyLine(index: number) {
    this.dialogService
      .open({
        title: 'Copy Line',
        content: 'All values from original line will be copied.  Be sure to review before saving.',
        actions: [
          {
            text: 'Cancel',
          },
          {
            text: 'Copy',
            themeColor: 'primary',
            primary: true,
          },
        ],
      })
      .result.pipe(filter((res: CustomDialogResult) => !(res instanceof DialogCloseResult) && res.primary))
      .subscribe((res) => {
        this.copyLine(index);

        this.totalQuantity = null;
        this.contractValue = null;
        this.contractAmount = null;
      });
  }

  copyLine(index: number) {
    let line = (this.form.get('lines') as TypedFormArray<ContractLineForm>).controls[index];

    if (!line) return;

    const newLineForm = this.addLine();
    let oldValue = line.value;
    let { id, lineNumber, ...newValue } = oldValue;
    newLineForm.patchValue(newValue, { emitEvent: true });
    this.form.markAsTouched();
    this.form.markAsDirty();

    setTimeout(() => {
      let lines: HTMLElement[] = this.elementRef.nativeElement.querySelectorAll('.contract-line');
      if (lines && lines.length > 0) {
        lines[lines.length - 1].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    });
  }

  advanceValueValidator() {
    return (control: AbstractControl) => {
      if (!this.form) return null;
      if (control.value === undefined || control.value === null) return null;
      let advanceType = this.form.value.advanceType;
      if (advanceType === AdvanceTypes.PERCENTAGE) {
        if (control.value > 100 || control.value < 0) return { custom: 'Amount must be between 0 and 100' };
      } else if (advanceType === AdvanceTypes.AMOUNT) {
        if (control.value < 0) return { custom: 'Amount must be non-negative' };
      }
      return null;
    };
  }

  requiredExpenseValidator(expense: TypedFormGroup<ContractExpenseForm>) {
    return (control: AbstractControl) => {
      if (!!expense.value.amount || !!expense.value.budgetElement || !!expense.value.id) {
        return Validators.required(control);
      }
      return null;
    };
  }

  expenseAmountValidator(beControl: AbstractControl) {
    return (control: AbstractControl) => {
      if (control.value === null) return;
      if (beControl.value && beControl.value.requiresOceanRate === YN.Y) return null;
      if (control.value <= 0) return { custom: 'Amount must be more than 0' };
      return null;
    };
  }

  shipmentEndValidator(shipmentStartControl: AbstractControl) {
    return (control: AbstractControl) => {
      let startDate: Date | null = shipmentStartControl.value ? new Date(shipmentStartControl.value) : null;
      let endDate: Date | null = control.value ? new Date(control.value) : null;

      if (startDate === null || typeof startDate === 'string') return;
      if (endDate === null || typeof endDate === 'string') return;

      startDate.setUTCHours(0, 0, 0, 0);
      endDate.setUTCHours(0, 0, 0, 0);

      if (startDate > endDate) {
        return { custom: 'End date must be later than start date' };
      }

      return null;
    };
  }

  protected async clickValuateContract() {
    if (this.form.touched || this.form.invalid) {
      this.clickSave();
    } else {
      const rid = this.spinnerService.startRequest('Valuating Contract');
      const response = await lastValueFrom(this.api.rpc<{ price: number }>(endpoints.valuateContract, { contractId: this.entity.id, updatePrice: true }, null, { blockRedirect: true }));
      this.spinnerService.completeRequest(rid);
      if (!!response) this.notificationService.show('Contract Valuated', 'success');
      else this.notificationService.show('Valuation failed', 'warning');
      this.router.navigate([this.router.url]);
    }
  }

  get currencyPerUnit() {
    let currency = this.currency ? this.currency.code : '?';
    let unit = this.priceUnit ? this.priceUnit.code : '?';

    return `${currency}/${unit}`;
  }
  get quantityCode() {
    return this.quantityUnit ? this.quantityUnit.code : '?';
  }

  get deleteLineAuthorized() {
    return this.entityName === 'Contract' ? this.authorized[endpoints.deleteContractLine] : this.authorized[endpoints.deleteDraftLine];
  }

  get deleteClauseAuthorized() {
    return this.entityName === 'Contract' ? this.authorized[endpoints.deleteContractClause] : this.authorized[endpoints.deleteDraftClause];
  }

  get deleteExpenseAuthorized() {
    return this.entityName === 'Contract' ? this.authorized[endpoints.deleteContractExpense] : this.authorized[endpoints.deleteDraftExpense];
  }

  get fixationsAuthorized() {
    return this.authorized[endpoints.listContractShipmentsAndFixationData];
  }

  get invoicesAuthorized() {
    return this.authorized[endpoints.listContractInvoicesAndAdvances];
  }

  isString(val: any): val is string {
    return typeof val === 'string';
  }

  protected saveEntity(options?: SaveOptions<PhysicalDraft | PhysicalContract>) {
    let form = this.form.value;

    let conflicts: string[] = [];

    if (form.product) {
      form.lines.forEach((l) => {
        let mtm = l.pricing.mtmValuationId ? this.commonDataService.staticMarketValuations.value.find((mv) => mv.valuationId === l.pricing.mtmValuationId) : null;
        let primaryMarket =
          l.pricing.priceType === LinePriceType.FORMULA && !!l.pricing.priceValuationId
            ? this.commonDataService.staticMarketValuations.value.find((mv) => l.pricing.priceType === LinePriceType.FORMULA && mv.valuationId === l.pricing.priceValuationId)
            : null;
        let premiumMarket =
          l.pricing.priceType === LinePriceType.FORMULA && !!l.pricing.premiumValuationId
            ? this.commonDataService.staticPremiumValuations.value.find((mv) => l.pricing.priceType === LinePriceType.FORMULA && mv.valuationId === l.pricing.premiumValuationId)
            : null;

        if (mtm && form.product.productId !== mtm.productId) {
          conflicts.push(`${l.lineNumber ? `Line ${l.lineNumber}` : 'New Line'} has an MTM that does not match the Contract's Product`);
        }
        if (primaryMarket && form.product.productId !== primaryMarket.productId) {
          conflicts.push(`${l.lineNumber ? `Line ${l.lineNumber}` : 'New Line'} has a Price Market that does not match the Contract's Product`);
        }
        if (premiumMarket && form.product.productId !== premiumMarket.productId) {
          conflicts.push(`${l.lineNumber ? `Line ${l.lineNumber}` : 'New Line'} has a Premium Market that does not match the Contract's Product`);
        }
      });
    }

    let willSave: Observable<boolean> =
      conflicts.length === 0 ? of(true) : this.promptService.simpleConfirmation('Conflicting Products', conflicts.join('\n') + '\n\n Are you sure you wish to save?', { confirmText: 'Save' });

    return willSave.pipe(
      switchMap((save) => {
        if (save) {
          return super.saveEntity(options);
        } else {
          return of(null);
        }
      })
    );
  }

  getTitle() {
    return this.entity && this.entityName === 'Contract' ? 'Contract ' + (this.entity.type === ContractType.PURCHASE ? 'MP' : 'MS') : 'Contract Draft';
  }

  getTabTitle() {
    return this.entity ? `${this.getTitle()} ${this.entity.number}` : `New ${this.entityName}`;
  }

  requiredIfNoFixationsValidator() {
    return (c: AbstractControl) => {
      return this.hasFixations ? null : Validators.required(c);
    };
  }

  async oceanRateFinder(expense: AbstractControl) {
    if (!this.oceanRatesAuthorized) return;
    const lines = this.linesFormArray.value;
    const destinationIds = lines.map((l: ContractLineForm) => l.destination.id);
    const uniqueDestinationIds = [...new Set(destinationIds)];
    const hasDifferentDestinations = uniqueDestinationIds.length > 1;

    const originId = this.form.value.incotermPlaceId;
    const destinationId = this.form.value.destinationId;
    let origin: City = undefined;
    let destination: City = undefined;

    const placeIds: number[] = [];
    let places: City[] = [];
    if (!!originId) placeIds.push(originId);
    if (!!destinationId) placeIds.push(destinationId);
    if (placeIds.length > 0) {
      const graphql = this.delegate.getService('graphql');
      await graphql
        .query<City[]>({
          query: getGraphqlRequestQuery<City>(GraphqlNames.simpleCitiesList, basicCityGraphqlRequest({ where: { id: { in: placeIds } } })),
          graphQlName: ExistingGraphql.places,
        })
        .then((res) => {
          places = res;
        });

      origin = places.find((c) => c.id === originId);
      destination = places.find((c) => c.id === destinationId);
    }

    const isToBeDetermined = destination ? destination.id === CommonCities.TO_BE_DETERMINED : true;

    const selector = this.delegate.getService('selector');
    selector
      .open(RateFinderComponent, {
        title: 'Ocean Rate Finder',
        initializer: (c) => {
          c.popup = true;
          c.filterForm.patchValue({ origin, destination });
          c.readonlyDestination = !isToBeDetermined;
          c.hasDifferentDestinations = hasDifferentDestinations;
          c.isDraftOrContract = true;
          c.lines = lines;
        },
        width: '95%',
        height: '90%',
        returnData: true,
      })
      .subscribe((res: OceanRateLookupData | RateRequestedResponse) => {
        if (res) {
          if (isOceanRateLookupDataType(res)) {
            let currencyId: number | null = null;
            if (!!this.form.value.company && !!this.form.value.company.fiscalCompany) currencyId = this.form.value.company.fiscalCompany.currKey;

            const rateDestination = res.destinationName ? `Destination: ${res.destinationName}. ` : '';
            const agreement = res.agreement ? `Agreement: ${res.agreement.trim()}` : '';

            expense.patchValue({
              amount: res.totalCost,
              contact: {
                id: res.shippingLineId,
                displayName: res.shippingLineName,
              },
              byPacking: YN.Y, // HARDCODED
              currencyId: currencyId || CommonCurrencies.USD,
              notes: `${rateDestination}${agreement}`,
              oceanRateSegmentId: res.segmentId,
            });
          } else if (res.rateRequested) {
            expense.patchValue({
              amount: 0,
              contact: {
                id: null,
                displayName: '',
              },
              byPacking: YN.Y, // HARDCODED
              currencyId: CommonCurrencies.USD,
              notes: `Rate requested on ${new Date().toLocaleString()}. Destination: ${res.destination}.`,
              oceanRateSegmentId: null,
              rateRequested: YN.Y,
            });
          }
        }
      });
  }

  hideEstimatedMargin() {
    // Check if session user is the same as trader user, is the trader assitant or belong to the next user groups
    const isUserGroupValid =
      this.currentUserGroups &&
      this.currentUserGroups.some(
        (group) =>
          group &&
          [
            UserGroupsEnum.HEDGING,
            UserGroupsEnum.FINANCE,
            UserGroupsEnum.DIT,
            UserGroupsEnum.EXECUTIVE,
            UserGroupsEnum.EXECUTIVE_VP,
            UserGroupsEnum.TRADING_MANAGERS,
            UserGroupsEnum.CONTROLLER,
            UserGroupsEnum.ASSISTANT_CONTROLLER,
          ].includes(group.cn as UserGroupsEnum)
      );

    return !!this.entity && this.user && this.user.id !== this.entity.traderId && !isUserGroupValid && this.entity.trader && this.user.id !== this.entity.trader.traderAssistantId;
  }

  clauseTypeValidator() {
    return (control: AbstractControl) => {
      const clauses = (this.form.get('clauses') as TypedFormArray<Clause>).controls.filter((c) => {
        return c !== control.parent;
      });
      return clauses.some((c) => c.value.type === control.value) ? { custom: 'Contracts may only have one of each clause type' } : null;
    };
  }

  isDraftContract = () => this.entityName === 'Draft';

  get clausesFormArray() {
    return this.form.get('clauses') as TypedFormArray<Clause>;
  }

  get linesFormArray() {
    return this.form.get('lines') as TypedFormArray<ContractLineForm>;
  }

  get expensesFormArray() {
    return this.form.get('expenses') as TypedFormArray<ContractExpenseForm>;
  }

  get partiallyLocked() {
    return this.hasShipments || this.hasFixations || this.hasInvoices || this.hasAdvances;
  }
}

export type ContractForm = Pick<
  PhysicalContract,
  | 'id'
  | 'counterparty'
  | 'incoterm'
  | 'advanceValue'
  | 'advanceType'
  | 'approvals'
  | 'class'
  | 'type'
  | 'company'
  | 'date'
  | 'trader'
  | 'product'
  | 'counterpartyReference'
  | 'priceUnitId'
  | 'currencyId'
  | 'tolerance'
  | 'expectedNumberOfLoads'
  | 'clauses'
  | 'comments'
  | 'estimatedMargin'
  | 'quantityUnitId'
  | 'premiumUnitId'
  | 'shipmentLock'
  | 'collateral'
  | 'destinationId'
  | 'incotermPlaceId'
  | 'archived'
  | 'archivingDate'
> & {
  agent: Contact | null;
  lcInterestIncluded: YN;
  useMWTI: YN;
  mwti: number;
  lines: ContractLineForm[];
  paymentTerm: PaymentTerm;
  expenses: ContractExpenseForm[];
  documents: Document[];
};
export type ContractLineForm = Pick<
  ContractLine,
  | 'id'
  | 'item'
  | 'shipmentPeriodStart'
  | 'shipmentPeriodEnd'
  | 'shipmentPeriodType'
  | 'quantity'
  | 'containerTypes'
  | 'originCountry'
  | 'destination'
  | 'clientItem'
  | 'incotermPlace'
  | 'priceType'
  | 'lineNumber'
  | 'metalPercentage'
  | 'metalControlGroup'
  | 'recoveryPercentage'
  | 'finished'
> & {
  pricing: ContractFormPricing;
  usesRecoveryPercentage: YN;
};

export type DuplicateContractLineForm = ContractLineForm & {
  numberOfLoadsAdded: number;
};

export type ContractExpenseForm = Pick<
  PhysicalContractExpense,
  'id' | 'budgetElement' | 'amount' | 'contact' | 'notes' | 'mandatory' | 'userAdded' | 'currencyId' | 'byPacking' | 'oceanRateSegmentId' | 'rateRequested'
>;

export function getBaseLineRequest(line: ContractLineForm | DuplicateContractLineForm) {
  return {
    quantity: line.quantity,
    shipmentPeriodStart: line.shipmentPeriodStart,
    shipmentPeriodEnd: line.shipmentPeriodEnd,
    shipmentPeriodType: line.shipmentPeriodType,
    incotermPlaceId: line.incotermPlace ? line.incotermPlace.id : null,
    itemId: line.item ? line.item.id : null,
    clientItemId: line.clientItem ? line.clientItem.id : null,
    originCountryId: line.originCountry ? line.originCountry.id : null,
    destinationId: line.destination ? line.destination.id : null,
    priceType: line.priceType,
    containerTypes: (line.containerTypes || []).flatMap((cT) => {
      return !!cT.id ? { id: cT.id } : [];
    }),
    metalControlGroupId: line.metalControlGroup ? line.metalControlGroup.statusKey : null,
    recoveryPercentage: line.usesRecoveryPercentage === YN.Y && this.form.value.product?.recoveryPercentageEnabled === YN.Y ? line.recoveryPercentage : null,
  };
}

export function isOceanRateLookupDataType(data: OceanRateLookupData | RateRequestedResponse): data is OceanRateLookupData {
  return (data as OceanRateLookupData | OceanRateLookupData).rateId !== undefined;
}
