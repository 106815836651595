<div class="card desktop-h-100" *ngIf="!popup">
  <div class="card-header d-flex">
    <h4 class="flex-grow-1">Ocean Rate Finder</h4>
  </div>
  <div class="card-body" style="display: flex; flex-direction: column">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>
<ng-container *ngIf="popup">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #content>
  <div class="d-flex desktop-h-100" style="display: flex; flex-direction: column">
    <form [formGroup]="filterForm" class="form-container">
      <div class="row">
        <vertical-field *ngIf="isDraftOrContract && hasDifferentDestinations" name="Contract Line Destination" class="col-xl-2 col-lg-3 col-md-4 col">
          <simple-dropdown [formControlName]="'contractLineDestinationId'" [data]="contractLineDestinationDropdown" [valueField]="'id'" [textField]="'name'" [valuePrimitive]="true"></simple-dropdown>
        </vertical-field>
        <vertical-field name="Origin" class="col-xl-2 col-lg-3 col-md-4 col">
          <dropdown-graphql [formControlName]="'origin'" [dropdownConfig]="cityDropdown" [placeholder]="'Origin'" [ignoreReadonlyMode]="true"></dropdown-graphql>
        </vertical-field>
        <vertical-field name="Destination" class="col-xl-2 col-lg-3 col-md-4 col">
          <dropdown-graphql
            [formControlName]="'destination'"
            [dropdownConfig]="cityDropdown"
            [placeholder]="'Destination'"
            [readonly]="readonlyDestination"
            [ignoreReadonlyMode]="true"
          ></dropdown-graphql>
        </vertical-field>
      </div>
      <div style="margin-bottom: 10px; display: flex; flex-direction: row-reverse">
        <button type="submit" kendoButton (click)="clickSearch()" style="width: 100px">Search</button>
        <button kendoButton type="button" [icon]="'filter-clear'" (click)="resetFilters()" style="margin-right: 5px" title="Clear filters"></button>
      </div>
    </form>
    <div style="width: 100%; flex-grow: 1">
      <ag-grid-angular class="ag-theme-balham desktop-h-100" [ngClass]="{ hidden: data === null }" [rowData]="data" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"> </ag-grid-angular>
    </div>
    <div style="display: flex; flex-direction: row-reverse">
      <div>
        <button type="button" kendoButton (click)="requestOceanRate()" *ngIf="requestRateAuthorized">Request Rate</button>
      </div>
      <div><button type="button" kendoButton (click)="createOceanRate()" *ngIf="createRateAuthorized">New Rate</button></div>
      <div>
        <button type="button" kendoButton *ngIf="userEmailAuthorized" (click)="openRateRequestEmail('Truck')">Truck</button>
      </div>
      <div>
        <button type="button" kendoButton *ngIf="userEmailAuthorized" (click)="openRateRequestEmail('EN')">EN</button>
      </div>
      <div>
        <button type="button" kendoButton *ngIf="userEmailAuthorized" (click)="openRateRequestEmail('ES')">ES</button>
      </div>
    </div>
  </div>
</ng-template>
